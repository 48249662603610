import parse from 'html-react-parser';
import './App.css';

/**
 * Component representing a single card.
 *
 * @param {int} count
 *   Number of shapes on the card.
 * @param {('solid', 'empty', 'shaded')} fill
 *   Type of fill for the shape.
 * @param {('red', 'green', 'purple')} color
 *   Shape color.
 * @param {('pill', 'diamond', 'squiggle')} shape
 *   Shape.
 *
 * @return {ReactNode}
 *   React element of a card with the given properties.
 */
function Card({count, fill, color, shape}) {
  const shapeSvgs = {
    pill: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 200"><rect class="shape-main" height="100%" width="100%" rx="50%" ry="30%" /></svg>',
    diamond: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 200"><polygon class="shape-main" points="0,100 50,200 100,100 50,0" height="100%" width="100%"/></svg>',
    squiggle: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="26.7243 33.687 74.68 177.7"><path class="shape-main" d="M 43.2126 33.7083 C 73.1014 32.9201 95.9927 54.1047 98.5362 84.9129 C 99.5983 97.7773 97.8125 110.0161 92.6935 121.3693 C 83.3092 142.1825 85.6906 161.6761 96.2303 181.0824 C 99.2425 186.6285 101.3277 192.594 101.4002 199.1295 C 101.4571 204.2718 98.8345 207.7255 94.3673 208.856 C 77.8728 213.0303 61.5482 212.5922 46.5608 203.7104 C 33.7827 196.1379 29.0975 183.5601 28.6192 169.4449 C 28.0201 151.7596 34.1127 135.4647 40.3402 119.2881 C 47.7462 100.0502 46.1429 81.5057 36.2871 63.5159 C 33.3325 58.1229 29.7727 53.0474 27.6554 47.2067 C 25.6467 41.6657 26.7679 38.762 32.1981 36.4638 C 35.5542 35.0434 38.9647 33.6066 43.2126 33.7083 z" /></svg>'
  };

  // Generate number of shapes here.
  const renderShapes = () => {
    let shapes = [];
    for (let i=0; i < count; i++) {
      shapes.push(parse(shapeSvgs[shape]));
    }
    return shapes;
  };

  return (
      <div className={`game-card count-${count} fill-${fill} color-${color} shape-${shape}`}>
      {renderShapes()}
      </div>
  );
}
Card.allowedCounts = [1, 2, 3];
Card.allowedFills = ['solid', 'empty', 'shaded'];
Card.allowedColors = ['red', 'green', 'purple'];
Card.allowedShapes = ['pill', 'diamond', 'squiggle'];

/**
 * Component representing the game board.
 *
 * @param object deck
 *   The deck of cards to play on the board.
 *
 * @return {ReactNode}
 *   The loaded game board.
 */
function Board({deck}) {

  // Initialize the board with cards from the deck.
  const renderCards = () => {
    let cards = [];
    for (let i=0; i<Board.defaultSize; i++) {
      cards.push(deck.drawCard());
    }
    return cards;
  };

  return (
    <>
      <svg>
      <defs>
      <pattern id="diagonalHatch" width="5" height="5" patternUnits="userSpaceOnUse">
      <line x1="0" y1="0" x2="5" y2="0" style={{stroke: "currentColor", strokeWidth: "4"}} />
      </pattern>
      </defs>
      </svg>
      <div className="game-board">{renderCards()}</div>
      </>
  );
}
Board.defaultSize = 12; // Number of cards to initialize the board with.
Board.cardIncreaseCount = 3; // Number of cards to add to the board when there's no Set available.

/**
 * Deck of cards. NOT a component.
 */
class Deck {

  constructor() {
    this.cards = [];

    // Not using an init() method because y'know,
    // a deck of cards is absolutely useless without
    // cards. There is no valid half-state.
    this.#fillCards();
  }

  #fillCards() {
    for (const count of Card.allowedCounts) {
      for (const fill of Card.allowedFills) {
        for (const color of Card.allowedColors) {
          for (const shape of Card.allowedShapes) {
            this.cards.push(<Card count={count} fill={fill} color={color} shape={shape} />);
          }
        }
      }
    }
  }

  drawCard() {
    // Get a random index to pull.
    const i = Math.floor(Math.random() * (this.cards.length + 1));
    const card = this.cards.splice(i, 1);
    return card;
  }
}

/**
 * Game initializer
 */
function App() {

  // Initialize the card deck.
  const deck = new Deck();

  return (
      <>
      <h1>Welcome to Set</h1>
      <Board deck={deck}/>
      </>
  );
}

export default App;
